
import { ref, computed, watch } from "vue";
import processors from "@/processors.json";
import { plan } from "@/refs/account";
import { showProModal } from "@/refs/modals";

export default {
	components: {},
	setup: () => {
		const jobTypes = ([] as string[])
			.concat(...processors.map((p) => Object.keys(p.types)))
			.filter(
				(type) =>
					type.startsWith("inference.") && type.includes("txt2img")
			);

		const selectedJobType = ref<(typeof jobTypes)[number]>(
			"inference.sdxl.txt2img.v1"
		);

		const config = ref<Record<string, any>>({});

		const selectedProperties = computed(() => {
			const processor = processors.find(
				(processor) => selectedJobType.value in processor.types
			);

			// @ts-ignore
			const flatternProperties = (schema: any) => {
				if ("properties" in schema)
					return flatternProperties(schema.properties);

				if ("allOf" in schema)
					return Object.assign(
						{},
						...schema.allOf.map(flatternProperties).flat()
					);

				if ("oneOf" in schema)
					return flatternProperties(schema.oneOf[0]);

				return schema;
			};

			if (processor === undefined) return undefined;

			const schema = flatternProperties(processor.schema);

			console.log(schema);

			const _config = schema?.config || schema?.properties?.config || {};

			return flatternProperties(_config);
		});

		const defaultConfig = computed<Record<string, any>>(() => {
			const defaultConfig = {};

			for (const key in selectedProperties.value) {
				const defaultValue =
					selectedProperties.value[key]?.default ||
					selectedProperties.value[key]?.examples?.[0] ||
					undefined;

				// @ts-ignore
				if (defaultValue !== undefined && key !== "seed") {
					// don't set a default value for seeds
					// @ts-ignore
					defaultConfig[key] = defaultValue;
				}
			}

			return defaultConfig;
		});

		watch(defaultConfig, () => {
			config.value = {};

			for (const key in defaultConfig.value) {
				if (typeof defaultConfig.value[key] === "boolean") {
					config.value[key] = defaultConfig.value[key];
				}
			}
		});

		const payload = computed(() => {
			return {
				type: selectedJobType.value,
				config: {
					...defaultConfig.value,
					...config.value
				}
			};
		});

		const token = ref(localStorage.getItem("v2-token") || "");
		const remember = ref<boolean>(token.value !== "");
		const image = ref<string | undefined>(undefined);
		const isGenerating = ref<boolean>(false);
		const generationTime = ref<number | undefined>(undefined);

		const run = async () => {
			console.log({ ...payload.value });

			const startTime = Date.now();

			isGenerating.value = true;

			if (remember.value) {
				localStorage.setItem("v2-token", token.value);
			} else {
				localStorage.removeItem("v2-token");
			}

			const response = await fetch(
				"https://inference.prodia.com/v2/job",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "image/jpeg",
						Authorization: `Bearer ${token.value}`
					},
					body: JSON.stringify(payload.value)
				}
			);

			const _generationTime = Date.now() - startTime;

			if (response.status === 429) {
				await new Promise((resolve) => setTimeout(resolve, 1000));
				run();
			}

			// get image data
			const imageBlob = await response.blob();

			const imageUrl = URL.createObjectURL(imageBlob);

			image.value = imageUrl;
			generationTime.value = _generationTime;
			isGenerating.value = false;
		};

		const copyCurl = () => {
			const curlCommand = `curl --request POST \\
--url https://inference.prodia.com/v2/job \\
     --header 'accept: image/jpeg' \\
     --header 'content-type: application/json' \\
	 --header 'authorization: Bearer ${token.value}' \\
     --data '
${JSON.stringify(payload.value, null, "\t")}
' \\
--output image.jpg
`;

			navigator.clipboard.writeText(curlCommand);
		};

		return {
			plan,
			jobTypes,
			selectedJobType,
			selectedProperties,
			config,
			payload,
			token,
			remember,
			image,
			run,
			isGenerating,
			generationTime,
			copyCurl
		};
	}
};
